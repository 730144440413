import { ProjectTechnology } from '@core/interfaces/project-technology';
import { Configuration } from '../../features/hub/projects/project/overview-project/configuration/service/configuration';
import { Contract } from '@core/interfaces/contract';
import { HubModuleFull } from '@core/interfaces/module';
import { EntityVersion } from '@core/interfaces/hub-entity-type';

export interface HubProject {
  id: string;
  description: string;
  name: string;
  version: number;
  bookmarked: boolean;
  modifiedAt: Date | string;
  modifiedBy: string;
  createdAt: Date | string;
  additionalInfo?: ProjectAdditionalInfo;
  isSelected?: boolean; // used for UI - radio btn
  // THIS SHOULD BE AN ARRAY, FOLLOWING THE PATTERN IN ASSETS VERSION - MAURICIO
  versions: Record<string, EntityVersion>;
  isReadonly?: boolean; // user for UI
  isWriteonly?: boolean; // user for UI
  technology: ProjectTechnology;
  configuration?: Configuration;
  projectVersion?: number;
}

export interface ProjectAdditionalInfo {
  numOfModules?: number;
  numOfAssets?: number;
  numOfMethods?: number;
  attachedLabels?: string[];
  contractName?: string;
  contractId?: string;
}

export interface MethodAdditionalInfo {
  contractId?: string;
  contractName?: string;
  projectId?: string;
}

export interface GetProjectsGraphResponse {
  totalProjects: number;
  generated: number;
}

export interface DeployedProjectResponse {
  id: string;
  status: DeploymentStatus;
  details: object;
  properties: { serviceUrl: string };
}

export interface PullProjectResponse {
  id: string;
  description: string;
  name: string;
  configuration: Configuration;
  contract: Contract;
  licence: string;
  versionMetadata: EntityVersion;
  modules: HubModuleFull[];
}

export enum DeploymentStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  STARTED = 'STARTED',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export interface ProjectSuccessDeployData {
  status: DeploymentStatus.SUCCESS;
  serviceUrl: string;
}

export interface ProjectDeployData {
  status?: DeploymentStatus;
  deploymentQuotaException?: boolean;
}

export interface ProjectVersionLicense {
  id: string;
  project: {
    id: string;
    name: string;
    tag: number;
  };
  approvedObjects: {
    METHODS: string[];
    PREMIUM_METHODS: string[];
    SCREENS: string[];
  };
  source: string;
}

export interface IssueLicenseResult {
  project: {
    id: string;
    name: string;
    tag: number;
  };
  license: string;
}
